<template>
  <div class="card-grid">
    <ChartCard chartType="pie" questionType="unsafe_worktrips" />
    <ChartCard chartType="text" questionType="unsafe_worktrips_text" />
    <ChartCard chartType="bar-horizontal" questionType="worktrip_safety" full />
  </div>
</template>
<script>
import ChartCard from "../chart-cards/ChartCard.vue";
export default {
  metaInfo() {
    return {
      title: this.$t("dashboard.nav.safety"),
    };
  },
  components: { ChartCard },
};
</script>
<style lang=""></style>
